
$(document).ready(function() {
  /******************************
      Navbar toggle button
   ******************************/

  $(".second-button").click(function(){
      $(".animated-icon2").toggleClass("open");
    });
  /******************************
      BOTTOM SCROLL TOP BUTTON
   ******************************/

  // declare variable
  var scrollTop = $(".scrollTop");

  $(window).scroll(function() {
    // declare variable
    var topPos = $(this).scrollTop();

    // if user scrolls down - show scroll to top button
    if (topPos > 100) {
      $(scrollTop).css("opacity", "1");

    } else {
      $(scrollTop).css("opacity", "0");
    }

  }); // scroll END

  //Click event to scroll to top
  $(scrollTop).click(function() {
    $('html, body').animate({
      scrollTop: 0
    }, 800);
    return false;

  }); // click() scroll top EMD

  /*************************************
    LEFT MENU SMOOTH SCROLL ANIMATION
   *************************************/
  // declare variable
  var h1 = $("#h1").position();
  var h2 = $("#h2").position();
  var h3 = $("#h3").position();

  $('.link1').click(function() {
    $('html, body').animate({
      scrollTop: h1.top
    }, 500);
    return false;

  }); // left menu link2 click() scroll END

  $('.link2').click(function() {
    $('html, body').animate({
      scrollTop: h2.top
    }, 500);
    return false;

  }); // left menu link2 click() scroll END

  $('.link3').click(function() {
    $('html, body').animate({
      scrollTop: h3.top
    }, 500);
    return false;

  }); // left menu link3 click() scroll END

}); // ready() END

(function($) {
  $(document).ready(function() {
    var customSelect = $(".custom-select");

    customSelect.each(function() {
      var thisCustomSelect = $(this),
        options = thisCustomSelect.find("option"),
        firstOptionText = options.first().text();

      var selectedItem = $("<div></div>", {
        class: "selected-item"
      })
        .appendTo(thisCustomSelect)
        .text(firstOptionText);

      var allItems = $("<div></div>", {
        class: "all-items all-items-hide"
      }).appendTo(thisCustomSelect);

      options.each(function() {
        var that = $(this),
          optionText = that.text();

        var item = $("<div></div>", {
          class: "item",
          on: {
            click: function() {
              var selectedOptionText = that.text();
              selectedItem.text(selectedOptionText).removeClass("arrowanim");
              allItems.addClass("all-items-hide");
            }
          }
        })
          .appendTo(allItems)
          .text(optionText);
      });
    });

    var selectedItem = $(".selected-item"),
      allItems = $(".all-items");

    selectedItem.on("click", function(e) {
      var currentSelectedItem = $(this),
        currentAllItems = currentSelectedItem.next(".all-items");

      allItems.not(currentAllItems).addClass("all-items-hide");
      selectedItem.not(currentSelectedItem).removeClass("arrowanim");

      currentAllItems.toggleClass("all-items-hide");
      currentSelectedItem.toggleClass("arrowanim");

      e.stopPropagation();
    });

    $(document).on("click", function() {
      var opened = $(".all-items:not(.all-items-hide)"),
        index = opened.parent().index();

      customSelect
        .eq(index)
        .find(".all-items")
        .addClass("all-items-hide");
      customSelect
        .eq(index)
        .find(".selected-item")
        .removeClass("arrowanim");
    });
  });
})(jQuery);
