$(function(){
  $(".withdrawal_methods input[type='radio']").on("change", toggleWithdrawalMethods);
  if($(".withdrawal_methods input[type='radio']").length > 0){ toggleWithdrawalMethods() }

  $(".deposit_methods input[type='radio']").on("change", toggleDepositMethods);
  if($(".deposit_methods input[type='radio']").length > 0){ toggleDepositMethods() }
});

function toggleDepositMethods(){
  $('.deposit_method_toggle').hide();

  switch($(".deposit_methods input[type='radio']:checked").val()){
    case 'bank_transfer':
      $('.deposit_method_toggle.bank_transfer').show();
      break;
    case 'local_bank_transfer':
      $('.deposit_method_toggle.local_bank_transfer').show();
      break;
    case 'wallet_transfer':
      $('.deposit_method_toggle.wallet_transfer').show();
      break;
  }
}

function toggleWithdrawalMethods(){
  $(".paywallet").hide();
  $(".bank_details").hide();
  $(".crypto").hide();

  switch($(".withdrawal_methods input[type='radio']:checked").val()){
    case 'bank_transfer':
      $('.bank_details').show();
      break;
    case 'paywallet':
      $('.paywallet').show();
      break;
    case 'bitcoin': case 'ethereum':
      $('.crypto').show();
      break;
  }
}